html, body {
  padding: 0;
  margin: 0;
  background-color: #eaeaea;
}

div{
  box-sizing: border-box;
}

.split-frame{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  min-height: 100vh;
}

.split-frame.mobile-push{
  overflow: hidden;
  height: 100vh;
}

.side-nav{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  background-color: #fff;
  margin: 20px 0 20px 20px;
  border-radius: 20px;

  min-height: 720px;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.side-nav-logo-wrap{
  cursor: pointer;
  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 16px;
  border-radius: 12px;
  background-color: #0055ff;
  height: 48px;

  color: #fff;
  font-weight: 700;

  &:hover{
    background-color: #004ade;
  }
}

.split-frame-right-wrap{
  background-color: #fff;
  border-radius: 20px;
  margin: 20px;
  width: 100%;
  padding: 36px 8px 36px 36px;

  overflow: hidden;
  min-height: 720px;

  display: flex;

  @media screen and (max-width: 960px) {
    margin: 0;
    padding: 48px 0px 12px 12px;
  }
}

.split-frame-right{
  width: 100%;
  padding-right: 12px;

  @media screen and (max-width: 960px) {
  padding-right: 4px;
  }
}

.split-frame-right.mobile-push{
  overflow: hidden;
  height: 100vh;
}

.split-frame-title{
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
  padding-left: 12px;
  width: 100%;
  height: 34px;

  font-size: 24px;
  font-weight: 700;

  border-left: 4px solid #1677FF;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.split-frame-title-divide{
  width: 100%;
  height: .5px;
  background-color: #e5e5e5;
}

.body-option-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  flex-wrap: wrap;

  border: .5px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px 0;
  padding-right: 16px;

  width: fit-content;

  @media screen and (max-width: 960px) {
    margin: 12px 0 12px 0;
  }
}

.body-option-row-inner{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 50px;
}

.body-option-label{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;

  font-size: 14px;
  font-weight: 700;
  color: #000;

  background-color: #fafafa;
  width: 100px;

  @media screen and (max-width: 960px) {
    padding: 0 4px;
    width: 72px;
  }
}

.body-option-item{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding: 0 16px;
  font-size: 14px;
}

.body-option-btn-wrap{
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.selected-group-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  font-size: 20px;
  font-weight: 600;

  margin-bottom: 20px;
}

.selected-group-title-back{
  cursor: pointer;

  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;
  border: 12px;
  background-color: #f5f5f5;
  width: 44px;
  height: 44px;
  border-radius: 8px;

  &:hover{
    background-color: #eaeaea;
  }
}

.selected-group-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-weight: 700;
  font-size: 14px;

  margin-bottom: 8px;
}

.divide{
  width: 100%;
  height: .5px;
  background-color: #dfdfdf;

  margin: 12px 0;
}

.change-owner-wrap{
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 24px;
}

.gap24{
  height: 24px;
}

.gap8{
  height: 8px;
}

.table-btns-wrap{
  margin-bottom: 12px;
}

.table-btns-wrap2{
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-cand-voice-body{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-label{
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.full-frame{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
}

.login-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  width: 280px;
  margin-top: 120px;
}

.call-btn-wrap{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  &:hover{
    opacity: .7;
  }
}

.call-btn-wrap.disabled{
  cursor: not-allowed;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  opacity: .3;

  &:hover{
    opacity: .3;
  }
}

.side-nav-log-out{
  cursor: pointer;

  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  height: 40px;
  padding: 0 16px 0 24px;
  margin: 4px 4px 24px 4px;
  border-radius: 8px;

  &:hover{
    background-color: rgba(255, 50, 50, .15);
    color: rgb(255, 50, 50);
  }
}

.logo-text{
  margin-bottom: 8px;

  img {
    height: 24px;
  }
}

.table-mobile-wrap{
  display: none;
  gap: 8px;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.table-desktop-wrap{
  display: block;
  @media screen and (max-width: 960px) {
    display: none;
  }
}

.voter-list-card-tags-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.voter-list-card{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 16px;
  border: .5px solid #dfdfdf;
  border-radius: 12px;
}

.voter-list-card-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.voter-list-card-call-number{
  font-size: 14px;
  font-weight: 500;
}

.voter-list-card-call-btn{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: rgba(22, 119, 255, .15);

  font-size: 20px;
}

.mobile-resolution{
  display: none !important;
  @media screen and (max-width: 960px) {
    display: flex !important;
  }
}

.desktop-resolution{
  display: flex !important;
  @media screen and (max-width: 960px) {
    display: none !important;
  }
}

.mobile-search-btn{
  margin-bottom: 20px;
}

.mobile-pagination-wrap{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 0;
}

.mobile-push-frame{
  position: fixed;
  top: 0;
  left: 0;

  z-index: 210;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: white;
  width: 100vw;
  height: 100vh;
}

.mobile-push-frame-body{
  padding: 64px 16px 36px 16px;

  height: calc(100vh);
  overflow: auto;
}

.mobile-nav{
  position: fixed;
  top: 0;
  left: 0;

  z-index: 200;

  width: 100vw;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  border-bottom: .5px solid #dfdfdf;
}

.mobile-nav-btn{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;
  width: 44px;

  font-size: 24px;
}

.mobile-nav-title{
  font-size: 18px;
  font-weight: 500;
}

.mobile-push-submit-btns{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.call-push-frame{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  height: 600px;
  width: 400px;
  border-radius: 20px;
  padding: 16px;

	background: linear-gradient(-45deg, #ffddaa, #f7aadd, #aaddff, #aaffdd);
	background-size: 300% 300%;
	animation: gradient 15s ease infinite;

  @media screen and (max-width: 960px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-top: 36px;
    justify-content: flex-start;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.call-push-bg{
  position: fixed;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);

  @media screen and (max-width: 960px) {
    background: none;
    width: fit-content;
    height: fit-content;
  }
}

.call-push-hang-up{
  cursor: pointer;

  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: #ff3355;

  font-size: 36px;
  color: white;

  &:hover{
    background-color: #ff5579;
  }
}

.call-push-timer{
  font-size: 14px;
}

.call-push-number{
  font-size: 20px;
  font-weight: 500;
}

.call-push-wrap{
  position: fixed;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 960px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    gap: 0;
  }
}

.call-push-sub{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 400px;
  height: 600px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 4px 20px 20px;
  overflow: hidden;

  @media screen and (max-width: 960px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60vh;
  }
}

.call-push-sub-wrap{
  flex: 1 0 0;
  padding-right: 16px;
}

.scroll-area{
  overflow: auto;
}
.scroll-area::-webkit-scrollbar {
  width: 10px;
}
.scroll-area::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.scroll-area::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.call-push-sub-helper{
  font-size: 13px;
  margin-top: 12px;
}

// download
.download-page-frame{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  background-color: #f0f0f0;
  height: 100vh;
  padding-top: 64px;
  padding-bottom: 16px;

  @media screen and (max-width: 480px) {
    height: auto;
    min-height: 100vh;
  }
}

.download-title{
  font-size: 20px;
  font-weight: 700;
}

.download-items-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;

      padding: 0 16px;
    
    .download-item-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}

.download-item{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 120px;
  border-radius: 20px;
  // background-color: #f5f5f5;

  img{
    width: 96px;
    height: 96px;
  }
}

.download-btns-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  padding: 0 0 20px 0;
}

.download-btn{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  color: #1677FF;

  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 12px 0;
  width: 200px;
}

.download-item-column{
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid #000;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
}

.select-assign-wrap{
  display: flex;
  flex-direction: column;

  padding: 20px;
  margin: 16px 0;
  border: .5px solid #dfdfdf;
  border-radius: 12px;
}

.uploaded-file-name{
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 12px;
  margin: 24px 0;
  font-size: 16px;
  font-weight: 500;
}

.play-voice-btn{
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  padding: 12px 16px;
  border: 1px solid #000;
  border-radius: 36px;

  font-size: 16px;
  font-weight: 500;
}

.flex-gap-12{
  gap: 12px;
}